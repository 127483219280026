// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en

@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import 'mixins/typography';
@import 'fonts';
@import 'print';
@import 'colors';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: @primary-palette[500];
@error-color: @event-types[error];
@warning-color: @event-types[warning];

@border-radius-base: 8px;
@checkbox-border-radius: 2px;

* {
    font-family: @font-family-set[primary];
}

h1 {
    .text-20-24-500();
}

input[readonly] {
    background-color: @border-color[default];
    cursor: not-allowed;
}

.ant-tabs-nav {
    a {
        color: inherit;
    }
}
