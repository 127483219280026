.text-11-13-400() {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
}

.text-11-13-500() {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
}

.text-11-13-600() {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
}

.text-11-16-400() {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.08em;
}

.text-11-28-500() {
    font-weight: 500;
    font-size: 11px;
    line-height: 28px;
    letter-spacing: 0.02em;
}

.text-11-16-700() {
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.08em;
}

.text-12-14-300() {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
}

.text-12-14-400() {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
}

.text-12-14-700() {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
}

.text-13-16-300() {
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
}

.text-13-16-400() {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
}

.text-13-16-600() {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
}

.text-13-16-700() {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
}

.text-14-17-300() {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.text-14-17-400() {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.text-14-17-500() {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.text-14-17-600() {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.text-14-17-700() {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.text-14-20-400() {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.text-14-22-400() {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
}

.text-14-28-300() {
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.02em;
}

.text-14-28-400() {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.02em;
}

.text-14-28-500() {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.02em;
}

.text-16-19-500() {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.02em;
}

.text-16-19-600() {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.02em;
}

.text-16-19-700() {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.02em;
}

.text-16-20-400() {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.text-20-24-400() {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.text-20-24-500() {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.text-20-24-700() {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.text-24-20-600() {
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
}
