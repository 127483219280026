@media print {
    *,
    *:before,
    *:after {
        color: #000;
        box-shadow: none;
        text-shadow: none;
    }

    un-header {
        display: none !important;
    }

    button {
        display: none !important;
    }

    nz-sider {
        display: none;
    }

    p a {
        word-wrap: break-word;
    }

    a[href^='http']:after {
        content: ' (' attr(href) ')';
    }

    table {
        page-break-after: auto;
    }

    td {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    thead {
        display: table-header-group;
    }

    ::-webkit-scrollbar {
        @media print {
            display: none;
        }
    }

    nz-table {
        .ant-table {
            &-thead > tr > th {
                background: white;
            }

            &-container {
                border-left: none !important;

                & > .ant-table-content > table > thead > tr > th {
                    border-top: 1px solid black;
                }

                table > thead > tr:first-child th:first-child {
                    border-top-left-radius: 0;
                }

                table > thead > tr:first-child th:last-child {
                    border-top-right-radius: 0;
                }

                & > .ant-table-content > table > tbody > tr > td:first-child,
                & > .ant-table-content > table > thead > tr > th:first-child {
                    border-left: 1px solid black;
                }

                & > .ant-table-content > table,
                & > .ant-table-header > table {
                    border-top: 0;
                }
            }

            &-cell {
                border-color: black !important;
            }
        }
    }
}
