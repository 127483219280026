@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Black/SFUIDisplay-Black.woff2') format('woff2'),
        url('/assets/fonts/Black/SFUIDisplay-Black.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Medium/SFUIDisplay-Medium.woff2') format('woff2'),
        url('/assets/fonts/Medium/SFUIDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Bold/SFUIDisplay-Bold.woff2') format('woff2'),
        url('/assets/fonts/Bold/SFUIDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Heavy/SFUIDisplay-Heavy.woff2') format('woff2'),
        url('/assets/fonts/Heavy/SFUIDisplay-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Semibold/SFUIDisplay-Semibold.woff2') format('woff2'),
        url('/assets/fonts/Semibold/SFUIDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Regular/SFUIDisplay-Regular.woff2') format('woff2'),
        url('/assets/fonts/Regular/SFUIDisplay-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Thin/SFUIDisplay-Thin.woff2') format('woff2'),
        url('/assets/fonts/Thin/SFUIDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Light/SFUIDisplay-Light.woff2') format('woff2'),
        url('/assets/fonts/Light/SFUIDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('/assets/fonts/Ultralight/SFUIDisplay-Ultralight.woff2') format('woff2'),
        url('/assets/fonts/Ultralight/SFUIDisplay-Ultralight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Consolas';
    src: url('/assets/fonts/Regular/Consolas-Regular.woff2') format('woff2'),
        url('/assets/fonts/Regular/Consolas-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Consolas';
    src: url('/assets/fonts/Bold/Consolas-Bold.woff2') format('woff2'),
        url('/assets/fonts/Bold/Consolas-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-family-set: {
    primary: 'SF UI Display', sans-serif;
    secondary: 'Consolas', sans-serif;
};
